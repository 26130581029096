@tailwind base;
@tailwind components;
@tailwind utilities;
/* globals.css */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap");

body {
  -webkit-font-smoothing: antialiased;
  /* https://www.joshwcomeau.com/css/custom-css-reset/#digit-font-smoothing */
  @apply bg-white;
  @apply text-cream;
  overflow-x: hidden;
}

html {
  @apply scroll-smooth;
}

.shadowMembershipCard {
  box-shadow: 0px 0px 41.29px 18.351px rgba(0, 0, 0, 0.08);
  border-radius: 18px;
}

@keyframes fall-in {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

.fadeInUpLazy {
  animation-name: fadeInUp;
  animation-duration: 0.8s;
  animation-fill-mode: both;
}

.tf-v1-widget {
  @apply !h-auto;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  input::-webkit-date-and-time-value {
    @apply h-[1.25em];
  }

  input::-webkit-datetime-edit-year-field,
  input::-webkit-datetime-edit-month-field,
  input::-webkit-datetime-edit-day-field,
  input::-webkit-datetime-edit-hour-field,
  input::-webkit-datetime-edit-minute-field,
  input::-webkit-datetime-edit-second-field,
  input::-webkit-datetime-edit-millisecond-field,
  input::-webkit-datetime-edit-meridiem-field {
    @apply p-0;
  }

  input::-webkit-datetime-edit {
    @apply p-0 inline-flex;
  }

  input[type="date"] {
    font-variant-numeric: normal;
  }

  .rte p,
  .rte h2,
  .rte h3,
  .rte li {
    @apply mb-6;
  }
}

@layer utilities {
  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .bg-gradient-radio {
    background-image: radial-gradient(
      var(--tw-gradient-from),
      35%,
      var(--tw-gradient-to) 36%
    );
  }

  .animate-slide-in {
    animation: slide-in 0.3s ease;
  }
  .animate-slide-inN {
    animation: slide-in 0.1s ease;
  }

  .animate-fall-in {
    animation: fall-in 0.3s ease;
  }

  .animate-fade-in {
    animation: fade-in 0.3s ease;
  }

  .animate-duration-8000 {
    animation-duration: 8s;
  }

  .adminItems:hover {
    background-color: rgba(184, 159, 91, 0.08);
    color: black !important;
  }

  @-webkit-keyframes autofill {
    0%,
    100% {
      color: #666;
      background: transparent;
    }
  }

  .autofill-transparent_auto:-webkit-autofill,
  .autofill-transparent_auto:-webkit-autofill:hover,
  .autofill-transparent_auto:-webkit-autofill:focus,
  .autofill-transparent_auto:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }

  .autofill-offblack:autofill {
    -webkit-box-shadow: 0 0 0 30px theme("colors.offblack") inset !important;
    -webkit-text-fill-color: theme("colors.cream") !important;
    caret-color: theme("colors.cream") !important;
  }
  .autofill-transparent_auto {
    transition: background-color 5000s ease-in-out 0s;
  }

  .clip-ellipse {
    clip-path: ellipse(50% 50%);
  }

  .rotate-top-right {
    transform: rotate(-90deg) translate(-100%, -50%);
  }
}

header {
  /* z-index: 99 !important; */
}

.signup-text {
  position: fixed;
  transform: rotate(-90deg);
  left: 0;
  top: 50%;
}

@media (max-width: 768px) {
  .signup-text {
    display: none;
  }
}
.text-white {
  color: #fff;
}
.no-scrollbar::-webkit-scrollbar {
  @apply w-0 h-0;
}

.no-scrollbar {
  -ms-overflow-style: none; /* for IE and Edge */
  scrollbar-width: none; /* for Firefox */
}
.scrollbar_sm::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}
.scrollbar_sm::-webkit-scrollbar-thumb {
  background-color: #8a8a85;
  border-radius: 2px;
}
@layer utilities {
  .scrollbar-xs::-webkit-scrollbar {
    width: 2px !important;
    height: 2px !important;
    background-color: transparent;
  }
}
.scrollbar_xs::-webkit-scrollbar-thumb {
  background-color: #8a8a85;
  border-radius: 8px !important;
}
::-webkit-scrollbar {
  width: 8px; /* Adjust the width as needed */
}
::-webkit-scrollbar-thumb {
  background-color: #8a8a85; /* Set the color of the scrollbar thumb */
  border-radius: 19px; /* Set the border radius as needed */
  height: 117px;
}

::-webkit-scrollbar-track {
  display: none;
}

.dialogContext {
  height: 100%;
  width: 480px;
  color: #272727;
  background: var(--rd-bone, #edede9);
  z-index: 100;
}

.dialog-shadow {
  box-shadow: -100px 100px 150px 0 rgba(52, 64, 84, 0.12);
}

@media screen and (max-width: 1024px) {
  .hide-on-mobile {
    display: none;
  }
}

.no_margin {
  margin: 0 !important;
}

tr td:last-child {
  width: 1% !important;
  white-space: nowrap !important;
  padding-right: 0 !important;
}

tr td {
  padding-right: 40px !important;
}

tr th:last-child {
  width: 20px !important;
}
.rm_browser_default_styles :-webkit-autofill,
.rm_browser_default_styles :-webkit-autofill:hover,
.rm_browser_default_styles :-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}

.truncate-container {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.reactEasyCrop_Container {
  width: 300px;
  height: 300px;
  top: 90px !important;
}

.reactEasyCrop_CropAreaRound {
  width: 300px !important;
  height: 300px !important;
}

.setPlayBtn {
  position: relative;
}
.delete-hover:hover {
  background-color: white;
}

@media (min-width: 1024px) {
  /* Adjust the min-width as needed for your target desktop size */
  .setPlayBtn:before {
    content: "";
    background: url("../public/assets/anaconference/ph_play-fill.png");
    width: 90px;
    height: 90px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.onlyMobile {
  display: none;
}
@media (max-width: 1024px) {
  .onlyMobile {
    display: block;
  }
}
.portfolio-search {
  position: relative;
}
.portfolio-search.fixed {
  position: fixed;
  top: 49px;
  right: 0;
  z-index: 1000;
  background-color: white !important;
}
.desktopOnly {
  display: block;
}
@media (max-width: 1024px) {
  .desktopOnly {
    display: none;
  }
}

@media (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}

.dashboard-portfolio tr th {
  padding: 0 0 20px 0;
}

.autofill-transparent:autofill {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-text-fill-color: #000000 !important;
  caret-color: #000000 !important;
  border: 0;
}
.hero {
  width: 100%;
  background-image: url("https://i.picsum.photos/id/100/2500/1656.jpg?hmac=gWyN-7ZB32rkAjMhKXQgdHOIBRHyTSgzuOK6U0vXb1w");
  background-position: center;
}

.hero:after {
  content: "";
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/7/76/1k_Dissolve_Noise_Texture.png");
  height: 300%;
  width: 300%;
  position: fixed;
  opacity: 0.04;
  animation: animateGrain 8s steps(10) infinite;
}

@keyframes animateGrain {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, -20%);
  }
  30% {
    transform: translate(-5%, -10%);
  }
  40% {
    transform: translate(-15%, -20%);
  }

  50% {
    transform: translate(-5%, -10%);
  }
  60% {
    transform: translate(-15%, -20%);
  }
  70% {
    transform: translate(-5%, -10%);
  }
  80% {
    transform: translate(-15%, -20%);
  }
  90% {
    transform: translate(-5%, -10%);
  }
  100% {
    transform: translate(-15%, -20%);
  }
}

.content-outline {
  width: 172px;
  height: 172px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.rounded-img {
  width: 172px;
  height: 172px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: 0 0 0 9999em;
  color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  border-radius: 50%;
}
.content-outline img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  will-change: transform;
}

.borderBottomNull {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.borderTopNull {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.box_shadow_current {
  -webkit-box-shadow: 0 0 0 5px rgba(184, 159, 91, 0.2);
  -moz-box-shadow: 0 0 0 5px rgba(184, 159, 91, 0.2);
  box-shadow: 0 0 0 5px rgba(184, 159, 91, 0.2);
}
.box_shadow_none {
  box-shadow: none;
}
.str-chat__list-notifications {
  display: none !important;
}
.ticker-wrap {
  width: 100%;
  height: 80px;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  position: fixed;
  bottom: 0;
  height: 3.5rem;
  position: absolute;
  left: 0;
}
.ticker {
  display: inline-block;
  margin-top: 5px;
  animation: marquee 50s linear infinite;
}
.item-collection-1 {
  position: relative;
  left: 0%;
  animation: swap 50s linear infinite;
}

.item {
  display: inline-block;
  padding: 0 4px;
  font-size: 12px;
  color: #fffff4;
  font-weight: 400;
  font-family: "Neue Montreal";
}

/* Transition */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes swap {
  0%,
  50% {
    left: 0%;
  }
  50.01%,
  100% {
    left: 100%;
  }
}

.text-red {
  color: #ff0000;
}

.rich-editor ol {
  list-style-type: decimal;
  margin-left: 1.5rem;
}

.rich-editor ul {
  list-style-type: disc;
  margin-left: 1.5rem;
}
.rich-editor [contenteditable] {
  direction: ltr;
}

.rm_browser_default_input_styles:-webkit-autofill {
  /* Remove the default yellow background */
  box-shadow: 0 0 0 1000px #fff inset !important;
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;

  /* Set a custom text color if needed */
  -webkit-text-fill-color: #000 !important;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

/* Apply animations based on the data-state attribute */
.slide-modal[data-state="open"] {
  animation: slideIn 300ms ease-out forwards;
}

.slide-modal[data-state="closed"] {
  animation: slideOut 300ms ease-in forwards;
}

/* Container and label styles */
.slider-container {
  width: 100%;
  margin: 1rem 0;
}
.slider-label {
  color: #101828;
  font-size: 1rem;
  font-weight: bold;
}
.slider-description {
  color: #7d7d7d;
  font-size: 0.875rem;
  margin: 0.5rem 0;
}
/* Slider layout */
.slider-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
/* Value labels above the slider */
.slider-value {
  position: absolute;
  top: -20px; /* Adjust as needed */
  background: transparent;
  color: #101828;
  font-size: 0.875rem;
}
/* Slider track and range highlight */
.slider-track {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #00000014;
  border-radius: 2px;
}
.slider-range {
  position: absolute;
  height: 100%;
  background-color: #b89f5b;
  border-radius: 2px;
}
/* Base styles for the range inputs */
.slider-input {
  position: absolute;
  width: 100%;
  height: 0;
  -webkit-appearance: none;
  background: transparent;
  pointer-events: none;
}
/* Custom slider thumb for Webkit browsers */
.slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border: 1.667px solid #b89f5b;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
  box-shadow:
    0px 3.333px 6.667px -1.667px rgba(14, 24, 41, 0.1),
    0px 1.667px 3.333px -1.667px rgba(14, 24, 41, 0.06);
}
/* Custom slider thumb for Firefox */
.slider-input::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: white;
  border: 2px solid #b89f5b;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
}
/* Custom slider thumb for IE */
.slider-input::-ms-thumb {
  width: 20px;
  height: 20px;
  background: white;
  border: 2px solid #b89f5b;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
}
/* Optional: Remove the default track styling for Firefox */
.slider-input::-moz-range-track {
  background: transparent;
}
.rta__textarea {
  min-height: 40px;
  max-height: 40px;
}
